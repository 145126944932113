<template>
  <div class="page more-depth">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">
          {{ ($__t("이메일") + " " + $__t("회원가입")) | capitalizeFirstLetter }}
        </div>
      </div>
      <div class="page-body">
        <ui-form ref="form" :formData="formData" @submit="submitForm" :ignoreSubmit="true">
          <ui-form-list>
            <ui-form-list-item>
              <lug-text-input
                v-model="formData.account.value"
                :label="formData.account.label"
                :placeholder="formData.account.placeholder"
                :error="formData.account.error"
                :type="formData.account.type"
              />
            </ui-form-list-item>
            <ui-form-list-item>
              <lug-text-input
                v-model="formData.password.value"
                :label="formData.password.label"
                :placeholder="formData.password.placeholder"
                :error="formData.password.error"
                :type="formData.password.type"
              />
            </ui-form-list-item>
            <ui-form-list-item>
              <lug-text-input
                v-model="formData.password_confirm.value"
                :label="formData.password_confirm.label"
                :placeholder="formData.password_confirm.placeholder"
                :error="formData.password_confirm.error"
                :type="formData.password_confirm.type"
              />
            </ui-form-list-item>
            <ui-form-list-item>
              <lug-text-input
                v-model="formData.user_name.value"
                :label="formData.user_name.label"
                :placeholder="formData.user_name.placeholder"
                :error="formData.user_name.error"
                :type="formData.user_name.type"
              />
            </ui-form-list-item>
          </ui-form-list>
        </ui-form>
      </div>

      <div class="page-footer">
        <button
          type="button"
          v-button
          @click="$refs.form.submit()"
          class="ui-submit-button"
          :disabled="!formActivated"
          :class="{ activated: formActivated }"
        >
          <span class="ui-submit-button__text">
            {{ $__t("회원가입") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        account: {
          type: "text",
          validation: [{ type: "required" }, { type: "email" }],
          value: "",
          label: this.$__t("이메일"),
          placeholder: this.$__t("이메일 입력"),
          error: "",
        },

        password: {
          type: "password",
          validation: [{ type: "required" }, { type: "passwordLength" }],
          value: "",
          label: this.$__t("비밀번호"),
          placeholder: this.$__t("8~20자"),
          error: "",
        },

        password_confirm: {
          type: "password",
          validation: [{ type: "required" }, { type: "matchField:password" }],
          value: "",
          label: this.$__t("비밀번호 확인"),
          placeholder: this.$__t("8~20자"),
          error: "",
        },

        user_name: {
          type: "text",
          validation: [{ type: "required" }],
          value: "",
          label: this.$__t("별명"),
          placeholder: this.$__t("Korean or English"),
          error: "",
        },
      },
    };
  },

  computed: {
    formActivated() {
      return Object.keys(this.formData).every((o) => !!this.formData[o].value);
    },

    isViewTypeIez() {
      return this.$store.getters["userData/GET_ITEM"]({ name: "__LUGSTAY__VIEW__TYPE__", noDefault: true }) === "iez";
    },

    campaignCode() {
      return this.$store.getters["userData/GET_ITEM"]({ key: "campaignCode" });
    },

    promotion() {
      return this.$store.getters["userData/GET_ITEM"]({ key: "promotion" });
    },
  },

  methods: {
    async submitForm(values) {
      try {
        this.$store.commit("loading/SET_TRUE");

        let { user_name, account, password } = values || {};

        let payload = {
          account,
          username: account,
          user_name,
          user_email: account,
          password,
        };

        for (let key in payload) {
          payload[key] = payload[key].trim();
        }

        await this.$store.dispatch("auth/register", payload);

        this.$analytics.logEvent("sign up complete", { provider: "Email" });
        this.$analytics.logEvent("login complete", { provider: "Email" });
        this.$analytics.tracking.call(this, "session", "login", "email");

        if (this.isViewTypeIez) {
          this.createInviteCouponForIezUser();
        }

        setTimeout(() => {
          this.$store.commit("loading/SET_FALSE");
        }, 1000);

        await this.$store.dispatch("auth/redirect");
      } catch (err) {
        this.$store.commit("loading/SET_FALSE");

        if (err === "DROP_OUT_USER") {
          this.$store.commit("alert/ADD_ITEM", { message: this.$__t(err), status: "error" });
          return;
        } else if (err === "ALREADY_USED_DATA") {
          this.$store.commit("alert/ADD_ITEM", {
            message: this.$__t("Your E-mail is already in use."),
            status: "error",
          });
        } else {
          this.$store.commit("alert/ADD_ITEM", { message: this.$__t(err), status: "error" });
        }
      }
    },

    async createInviteCouponForIezUser() {
      if (!(this.promotion && this.campaignCode)) return;

      try {
        return await this.axios.post(
          this.$store.state.config.apiURL + "/v2/campaigns/invite/" + this.campaignCode,
          {},
          { headers: this.$store.state.config.userHeaders }
        );
      } catch (e) {
        this.$log.error(e);
      }
    },

    checkAll() {
      return Object.keys(this.formData).forEach((o) => (this.formData[o].value = true));
    },
  },
};
</script>
